<div #content tabindex="0" role="dialog" aria-labelledby="title">
  <ion-toolbar>
    <ion-title class="padding-left-l">
      <h1 id="title" class="color-primary margin-top-l">Unlock this feature...</h1>
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button color="dark" icon-only (click)="close()" id="top-close-button">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="padding-l margin-bottom-l" #text id="message">
    To save results, login or register for an account with the University. It only takes a minute, and will keep your results for whenever
    you need them. And an added bonus, if you choose to apply to study with us in the future, you’ll use the same account.
  </div>
  <div class="flex-center">
    <ion-button shape="round" color="primary" (click)="goToLogin()" id="login-or-register">Login or Register</ion-button>
    <ion-button fill="outline" shape="round" color="primary" color="primary" (click)="close()" id="close">Cancel</ion-button>
  </div>
</div>
