import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule, ModalController } from '@ionic/angular';

import { UnlockFeatureModalComponent } from './unlock-feature-modal.component';

const COMPONENTS = [UnlockFeatureModalComponent];

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule],
  declarations: [COMPONENTS],
})
export class UnlockFeatureModalModule {
  private _elementClicked: any;
  constructor(private _modalCtrl: ModalController) {}

  public async presentModal(): Promise<HTMLIonModalElement> {
    this._elementClicked = document.activeElement;
    const modal = await this._modalCtrl.create({
      component: UnlockFeatureModalComponent,
      cssClass: 'calculator-info-modal',
    });
    await modal.present();
    modal.onDidDismiss().then((_) => {
      this._elementClicked.focus();
    });
    return modal;
  }
}
