import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppStorageService } from 'src/app/core/services';
import { NceaService } from 'src/app/core/services/ncea.service';
import { UserService } from 'src/app/core/services/user.service';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Input() pageType: string;

  constructor(
    public modalController: ModalController,
    public _userService: UserService,
    public aapStorage: AppStorageService,
    public nceaService: NceaService
  ) {}

  async ngOnInit() {}

  async presentModal() {
    const modal = await this.modalController.create({
      component: DisclaimerModalComponent,
      cssClass: 'disclaimer-modal',
    });
    await modal.present();
    modal.onDidDismiss().then((_) => {});
  }
}
