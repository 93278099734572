<div tabindex="0" role="dialog" aria-label="#top-close-button" class="modal-container">
  <ion-toolbar color="light">
    <ion-title color="primary">
      <h1 class="font-default margin-0" id="title">Permission to access NZQA</h1>
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" id="close-button" icon-only>
        <ion-icon name="close" id="close-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="modal">
    <div class="message padding-bottom-l">
      <div>To retrieve your NCEA results, we need to look up your NCEA results from the NZQA.</div>
      <div>By continuing with this request, you are authorising us to collect and use this information.</div>
      <div>
        We will use this information in accordance with the terms and conditions of this service and the University's Privacy Statement, and
        will not share it with any third parties.
      </div>
    </div>
    <div class="display-flex flex-reverse padding-top-l">
      <ion-button shape="round" (click)="continue()" id="continue-button">Continue</ion-button>
      <ion-button shape="round" fill="outline" (click)="dismiss()" id="cancel-button">Cancel</ion-button>
    </div>
  </div>
</div>
