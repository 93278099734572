import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NceaSubjectCardComponent } from './ncea-subject-card.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { NceaStandardModule } from '../ncea-standard/ncea-standard.module';

const COMPONENT = [NceaSubjectCardComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [IonicModule, CommonModule, NceaStandardModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NceaSubjectCardModule {}
