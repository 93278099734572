import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-nzqa-access-modal',
  templateUrl: './nzqa-access-modal.component.html',
  styleUrls: ['./nzqa-access-modal.component.scss'],
})
export class NzqaAccessModalComponent implements OnInit {
  constructor(public modalController: ModalController) {}

  ngOnInit() {}

  dismiss(permission = false): void {
    this.modalController.dismiss({
      permission,
    });
  }

  continue(): void {
    this.dismiss(true);
  }
}
