import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingIndicatorModule } from '../loading-indicator';
import { UnlockFeatureModalModule } from '../unlock-feature-modal/unlock-feature-modal.module';
import { ScoreBoxComponent } from './score-box.component';

const COMPONENTS = [ScoreBoxComponent];

@NgModule({
  imports: [CommonModule, FormsModule, UnlockFeatureModalModule, LoadingIndicatorModule],
  declarations: [...COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [...COMPONENTS],
})
export class ScoreBoxModule {}
