export class CieSubject {
  subjectCode: string;
  subjectName: string;
  testComponent: string;
  include?: boolean;

  constructor(subjectCode: string = '', subjectName: string = '', testComponent: string = '', include = false) {
    this.subjectCode = subjectCode;
    this.subjectName = subjectName;
    this.testComponent = testComponent;
    this.include = include;
  }
}

export class CieGrade {
  academicLevel: string;
  rankScore: number;
  result: string;
  subjectUnit: number;

  constructor(academicLevel: string = '', rankScore: number = 0, result: string = '', subjectUnit: number = 0) {
    this.academicLevel = academicLevel;
    this.rankScore = rankScore;
    this.result = result;
    this.subjectUnit = subjectUnit;
  }
}

export class ChosenSubjectGrade {
  subject: CieSubject;
  grade: CieGrade;
  gradesList: CieGrade[];
  constructor(subject: CieSubject = new CieSubject(), grade: CieGrade = new CieGrade(), gradesList = []) {
    this.subject = subject;
    this.grade = grade;
    this.gradesList = gradesList;
  }
}

export class CalculationRequestSubjects {
  testComponent: string;
  subjectCode: string;
  academicLevel: string;
  result: string;

  constructor(testComponent: string, subjectCode: string, academicLevel: string, result: string) {
    this.testComponent = testComponent;
    this.subjectCode = subjectCode;
    this.academicLevel = academicLevel;
    this.result = result;
  }
}
