import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, forkJoin, map, Observable, shareReplay, take } from 'rxjs';
import { ResponseDto } from 'src/app/domain';

import { CieSubject, CieGrade, CalculationRequestSubjects, ChosenSubjectGrade } from 'src/app/domain/cie-subject.model';
import { environment } from 'src/environments/environment';
import { AppStorageService } from './app-storage.service';
import { UserService } from './user.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CieService {
  public cieAllSubjects = new BehaviorSubject<CieSubject[]>([]);
  cieAllSubjects$: Observable<CieSubject[]>;

  private _calculatedTotalScore = new BehaviorSubject<string>('');
  calculatedTotalScore$: Observable<string>;

  private _chosenSubjects = new BehaviorSubject<ChosenSubjectGrade[]>([new ChosenSubjectGrade()]);
  chosenSubjects$ = new Observable<ChosenSubjectGrade[]>();

  private _calculatePushed = new BehaviorSubject<boolean>(false);
  calculatePushed$: Observable<boolean>;

  private _subjectModified = new BehaviorSubject<boolean>(false);
  subjectModified$: Observable<boolean>;

  constructor(public _http: HttpClient, public storageService: AppStorageService, private _userService: UserService) {
    this.cieAllSubjects$ = this.cieAllSubjects.asObservable().pipe(shareReplay(1));
    this.calculatedTotalScore$ = this._calculatedTotalScore.asObservable().pipe(shareReplay(1));
    this.calculatePushed$ = this._calculatePushed.asObservable().pipe(shareReplay(1));
    this.chosenSubjects$ = this._chosenSubjects.asObservable().pipe(shareReplay(1));
    this.subjectModified$ = this._subjectModified.asObservable().pipe(shareReplay(1));
  }

  initCie() {
    this.storageService.getItem('cieResults').then((val) => {
      if (val) {
        this._chosenSubjects.next(val);
      }
    });
    this._userService.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn === true),
        take(1)
      )
      .subscribe((_) =>
        this.getSavedSubjectsGrades()
          .pipe(take(1))
          .subscribe((result) => {
            let data = result.message.cieGrade as ChosenSubjectGrade[];
            if (0 < data?.length) {
              this._chosenSubjects.next(data);
            }
          })
      );
  }

  getSubjects(): void {
    this._http
      .get(`${environment.apiUrl}subjects/cie`)
      .pipe(take(1))
      .subscribe((x) => {
        this.cieAllSubjects.next(x['message']);
      });
  }

  getGrades(subjectCode: string, testComponent: string): Observable<CieGrade[]> {
    return this._http
      .get<Observable<CieGrade[]>>(`${environment.apiUrl}grades/fetch-grades?subjectCode=${subjectCode}&testComponent=${testComponent}`)
      .pipe(
        map((results) =>
          results['message']['results'].map((element: CieGrade) =>
            element['result'].includes('*') ? Object.assign(element, { result: element['result'].substring(0, 1) + '+' }) : element
          )
        )
      );
  }

  addNewRow(): void {
    this.chosenSubjects$.pipe(take(1)).subscribe((subjects) => {
      subjects.push(new ChosenSubjectGrade());
      this._chosenSubjects.next(subjects);
    });
  }

  resetChosenSubjects(): void {
    this._chosenSubjects.next([new ChosenSubjectGrade()]);
    this.gradesChanged();
    this._userService.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn === true),
        take(1)
      )
      .subscribe((_) => this.saveSubjectsGrades([]).pipe(take(1)).subscribe());
    this.storageService.removeItem('cieResults');
  }

  gradesChanged(): void {
    this._calculatePushed.next(false);
    this._calculatedTotalScore.next('');
  }

  subjectModified(): void {
    this._subjectModified.next(true);
  }

  calculate(): void {
    let requestSubjects = [];
    this.chosenSubjects$.pipe(take(1)).subscribe((subjectList) => {
      requestSubjects = subjectList
        .filter((sub) => sub.subject.testComponent != '')
        .map(
          (sub) =>
            new CalculationRequestSubjects(
              sub.subject.testComponent,
              sub.subject.subjectCode,
              sub.grade.academicLevel,
              sub.grade.result.replace('+', '*')
            )
        );
      if (requestSubjects.length > 0) {
        forkJoin({
          calculation: this.getCieCalculation(requestSubjects).pipe(take(1)),
          chosenSubjects: this.chosenSubjects$.pipe(take(1)),
        }).subscribe(({ calculation, chosenSubjects }) => {
          this._calculatedTotalScore.next(calculation.message['totalScore']);
          let responseSubjectArray = calculation.message['cieRankScore'];
          responseSubjectArray.forEach((respSubject) => {
            let index = chosenSubjects.findIndex((subject) => subject.subject.testComponent === respSubject.testComponent);
            chosenSubjects[index].subject.include = respSubject.include;
          });
          this._calculatePushed.next(true);
        });
      }
    });
  }

  getCieCalculation(requestSubjects: CalculationRequestSubjects[]): Observable<ResponseDto> {
    return this._http.post<ResponseDto>(`${environment.apiUrl}calculate/cie`, requestSubjects);
  }

  saveSubjectsGrades(grades: ChosenSubjectGrade[]): Observable<ResponseDto> {
    return this._http.put<ResponseDto>(`${environment.apiUrl}grades/save`, { cieGrade: grades });
  }

  getSavedSubjectsGrades(): Observable<ResponseDto> {
    return this._http.get<ResponseDto>(`${environment.apiUrl}grades/save`);
  }
}
