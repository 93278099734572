<div class="footer background-dark-3">
  <div>
    <a
      class="padding-left-m padding-right-m"
      target="_blank"
      rel="noopener"
      href="https://www.auckland.ac.nz/en/admin/footer-links/directory.html"
      >A to Z Directory
    </a>
    <a
      class="border-left-light-2 padding-left-m padding-right-m"
      target="_blank"
      rel="noopener"
      href="https://www.auckland.ac.nz/en/admin/footer-links/sitemap.html"
    >
      Site map
    </a>
    <a
      class="border-left-light-2 padding-left-m padding-right-m"
      target="_blank"
      rel="noopener"
      href="https://www.auckland.ac.nz/en/accessibility.html"
    >
      Accessibility
    </a>
  </div>
  <div class="section-2">
    <a target="_blank" class="padding-left-m padding-right-m" rel="noopener" href="https://www.auckland.ac.nz/en/copyright.html">
      Copyright
    </a>
    <a
      class="border-left-light-2 padding-left-m padding-right-m"
      target="_blank"
      rel="noopener"
      href="https://www.auckland.ac.nz/en/privacy.html"
    >
      Privacy
    </a>
    <a
      class="border-left-light-2 padding-left-m padding-right-m disclaimer"
      rel="noopener"
      tabindex="0"
      (keydown.enter)="presentModal()"
      (click)="presentModal()"
      >Disclaimer
    </a>
  </div>
</div>
