import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { environment } from 'src/environments/environment';
import { LoginService, UserInfoDto } from '@uoa/auth';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class UserService {
  loggedIn$: Observable<boolean>;
  hideLeavePopup = false;
  user$: Observable<UserInfoDto>;
  constructor(private _loginService: LoginService) {
    this._loginService.isAuthenticated();
    this.loggedIn$ = this._loginService.loggedIn$;
    this.user$ = this._loginService.userInfo$;

    console.log('Version: ', environment.version);
  }
}
