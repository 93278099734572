<ion-accordion toggle-icon-slot="end" [value]="subject.subjectCode + subject.year">
  <ion-item slot="header" color="light" class="header" detail="false" button="false">
    <ion-label>
      <h3>
        <span class="color-primary font-l bold year">{{ subject.year }} </span>
        <span class="color-primary font-l bold" id="subject-name">Level 3 {{ subject.subjectName }}</span>
      </h3>
    </ion-label>
  </ion-item>

  <div slot="content">
    <ion-item *ngIf="subject.standards.length > 0" lines="none">
      <ion-grid>
        <ng-container *ngFor="let standard of subject.standards; let i = index">
          <app-ncea-standard
            [index]="i"
            [standardsLength]="subject.standards.length"
            [standard]="standard"
            [scoreCalculated]="scoreCalculated$ | async"
            [subjectCode]="subject.subjectCode"
          ></app-ncea-standard>
        </ng-container>
      </ion-grid>
    </ion-item>
    <div class="suggestions font-default" *ngIf="subject.standardSuggestions.length > 0">
      <div class="add-standards-text padding-left-s">Add standards:</div>
      <div class="padding-left-s padding-bottom-s">
        <combo-search-dropbox
          [searchList]="subject.standardSuggestions"
          placeholder="Select Standard"
          (chosenItem)="validateAddStandard($event.detail)"
          [searchValue]="initialValue"
          primary-search-field="title"
          id="suggestion-dropdown"
        ></combo-search-dropbox>
      </div>
    </div>

    <div *ngIf="!subject.fromNzqa" class="delete border-top-light-2 border-bottom-light-2">
      <ion-button fill="clear" (click)="confirmDelete()" id="remove-button"><ion-icon name="trash"></ion-icon>Remove</ion-button>
    </div>
  </div>
</ion-accordion>
