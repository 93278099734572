import { Component, Input, OnInit } from '@angular/core';
import { NceaStandard } from 'src/app/domain';
import { NceaService } from 'src/app/core/services/ncea.service';
import { combineLatest, take } from 'rxjs';
import { AppStorageService } from 'src/app/core/services';
import { LoginService } from '@uoa/auth';

@Component({
  selector: 'app-ncea-standard',
  templateUrl: './ncea-standard.component.html',
  styleUrls: ['./ncea-standard.component.scss'],
})
export class NceaStandardComponent implements OnInit {
  @Input() standard: NceaStandard;
  @Input() index: number;
  @Input() standardsLength: number;
  @Input() scoreCalculated: boolean;
  @Input() subjectCode: string;

  notUsedText = `<br/>Your rank score is calculated based on your best 80 credits across a maximum of 5 subjects.<br/><br/>`;

  constructor(private _nceaService: NceaService, private _aapStorage: AppStorageService, private _loginService: LoginService) {}

  ngOnInit(): void {
    if (this.standard?.results?.length > 1) {
      this.standard.results.sort((a, b) => {
        return b.rankScore - a.rankScore;
      });
    }
  }

  gradeSelected(grade): void {
    if (!grade) {
      this.standard.rankScore = null;
      this.standard.result = null;
    }
    this.standard.result = grade;
    this._nceaService.rankScoreDisplayUpdate();
  }

  delete(): void {
    let refinedCategorisedSubjects = [];
    console.info('standard deleted', this.standard);
    combineLatest([this._nceaService.nceaSubjects$, this._nceaService.uncategorisedStandards$])
      .pipe(take(1))
      .subscribe(([categorisedSubjects, uncategorisedStandards]) => {
        categorisedSubjects.forEach((subject) => {
          let refinedStds = subject.standards.filter(
            (std) => !(std.year === this.standard.year && std.standardCode === this.standard.standardCode)
          );
          subject.standards = refinedStds;
          this.updateSuggestion(subject);
          refinedCategorisedSubjects.push(subject);
        });
        this._nceaService.rankScoreDisplayUpdate();
        this._nceaService.updateSubjectsObservable(refinedCategorisedSubjects);
        this._aapStorage.setItem('nceaResults', refinedCategorisedSubjects);
        this._loginService.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
          if (loggedIn) {
            this._nceaService.saveSubjectStandards(refinedCategorisedSubjects, uncategorisedStandards).pipe(take(1)).subscribe();
          }
        });
      });
  }

  updateSuggestion(subject): void {
    if (this.subjectCode === subject.subjectCode && this.standard.year === subject.year) {
      this._nceaService.getSuggestedStandards(subject);
    }
  }
}
