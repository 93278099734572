import { Injectable } from '@angular/core';
import { StorageService } from '@uoa/auth';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root',
})
export class AppStorageService implements StorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await this.storage.create();
    this._storage = storage;
  }

  getItem(key: string): Promise<any> {
    return this.storage.get(key);
  }

  setItem(key: string, val: any): Promise<void> {
    return this.storage.set(key, val);
  }

  removeItem(key: string): void {
    this.storage.remove(key);
  }
}
