import { Component, Input, OnInit } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { AppStorageService } from 'src/app/core/services';
import { CieService } from 'src/app/core/services/cie.service';
import { UserService } from 'src/app/core/services/user.service';
import { UnlockFeatureModalModule } from '../unlock-feature-modal/unlock-feature-modal.module';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NceaService } from 'src/app/core/services/ncea.service';

@UntilDestroy()
@Component({
  selector: 'app-score-box',
  templateUrl: './score-box.component.html',
  styleUrls: ['./score-box.component.scss'],
})
export class ScoreBoxComponent implements OnInit {
  @Input() pageType: string;
  totalRankScore: string;
  calculatedRankScore: string;
  loading = new BehaviorSubject<boolean>(false);
  saving = new BehaviorSubject<boolean>(false);
  disableCalculation: Observable<boolean>;

  constructor(
    public modalController: ModalController,
    private unlock: UnlockFeatureModalModule,
    public userService: UserService,
    public aapStorage: AppStorageService,
    public nceaService: NceaService,
    public cieService: CieService,
    private _toastCtrl: ToastController
  ) {}

  async ngOnInit() {
    if (this.pageType == 'cie') {
      this.totalRankScore = '420';
      this.disableCalculation = this.cieService.chosenSubjects$.pipe(
        map((subjects) => 0 === subjects.filter((sub) => sub.subject['testComponent']).length)
      );
    } else if (this.pageType == 'ncea') {
      this.totalRankScore = '320';
    }
  }

  onSaveResults() {
    this.saving.next(true);
    if (this.pageType == 'cie') {
      this.onCieResultSave();
    } else if (this.pageType == 'ncea') {
      this.onNceaResultSave();
    }
  }

  onNceaResultSave(): void {
    this.saveNceaResultsToStorage();
    this.userService.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) {
        combineLatest([this.nceaService.nceaSubjects$, this.nceaService.uncategorisedStandards$])
          .pipe(take(1))
          .subscribe(([subjects, uncategorised]) =>
            this.nceaService.saveSubjectStandards(subjects, uncategorised).subscribe(async (_) => await this.showToast())
          );
      } else {
        this.presentAlert();
      }
    });
  }

  async showToast(): Promise<void> {
    this.saving.next(false);
    const toast = await this._toastCtrl.create({
      message: 'Saved!',
      duration: 2500,
      position: 'bottom',
    });

    await toast.present();
  }

  onCieResultSave(): void {
    this.saveCieResultsToStorage();
    this.userService.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
      if (loggedIn) {
        this.cieService.chosenSubjects$
          .pipe(take(1))
          .subscribe((subjects) => this.cieService.saveSubjectsGrades(subjects).subscribe(async (_) => await this.showToast()));
      } else {
        this.presentAlert();
      }
    });
  }

  async presentAlert(): Promise<void> {
    const model = await this.unlock.presentModal();
    model.onDidDismiss().then(() => this.saving.next(false));
  }

  saveNceaResultsToStorage() {
    this.nceaService.nceaSubjects$.pipe(take(1)).subscribe((subjects) => this.aapStorage.setItem('nceaResults', subjects));
  }

  saveCieResultsToStorage() {
    this.cieService.chosenSubjects$.pipe(take(1)).subscribe((subjects) => this.aapStorage.setItem('cieResults', subjects));
  }

  calculate() {
    this.loading.next(true);
    if (this.pageType == 'cie') {
      this.cieService.gradesChanged();
      this.cieService.calculate();
      this.cieService.calculatedTotalScore$.pipe(untilDestroyed(this)).subscribe((x) => {
        this.calculatedRankScore = x;
        if (x != '') {
          this.loading.next(false);
        }
      });
    }
    if (this.pageType == 'ncea') {
      this.nceaService.rankScoreDisplayUpdate();
      this.nceaService.calculate();
      this.nceaService.calculatedTotalScore$.pipe(untilDestroyed(this)).subscribe((x) => {
        this.calculatedRankScore = x;
        if (x != '') {
          this.loading.next(false);
        }
      });
    }
  }
}
