import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService, UserInfoDto } from '@uoa/auth';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class HeaderComponent {
  userInfo$: Observable<UserInfoDto>;
  constructor(private _loginService: LoginService, private _router: Router, private _userService: UserService) {
    this.userInfo$ = this._userService.user$;
  }

  async login() {
    await this._loginService.doLogin(this._router.url);
  }
}
