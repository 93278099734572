import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Component, ViewChild, ElementRef } from '@angular/core';

import { environment } from 'src/environments/environment';

import { AlertController, MenuController } from '@ionic/angular';
import { LoginService, UserInfoDto } from '@uoa/auth';
import { shareReplay, take } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { NceaService } from 'src/app/core/services/ncea.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  loggedIn$: Observable<boolean>;
  user$: Observable<UserInfoDto>;
  hasNceaPermission$: Observable<boolean>;
  environment = environment;
  @ViewChild('title') title: ElementRef;

  constructor(
    private _userService: UserService,
    private _menuCtrl: MenuController,
    private _loginService: LoginService,
    private _nceaService: NceaService,
    private _router: Router,
    private _alertCtrl: AlertController
  ) {
    this.loggedIn$ = this._userService.loggedIn$;
    this.user$ = this._userService.user$;
    this.hasNceaPermission$ = this._nceaService.hasNceaPermission$.pipe(shareReplay(1));
  }

  async toggleNceaPermission(): Promise<void> {
    await this.login();
    this.hasNceaPermission$.pipe(take(1)).subscribe(async (permission) => {
      this._nceaService.saveNceaPermission(!permission);
      if (permission === true) {
        sessionStorage.setItem('nceaPopupShown', permission.toString());
        const alert = await this._alertCtrl.create({
          header: 'Turn off permission to acess NZQA',
          message: `Rank Score Calculator won't receive any future updates from  NZQA regarding your results.`,
          buttons: [{ text: 'Close', role: 'cancel' }],
        });
        await alert.present();
      }
    });
  }

  handleEscapeKey(event: KeyboardEvent): void {
    this._menuCtrl.close();
  }

  menuWillOpen(): void {
    if (this.title) {
      this.title.nativeElement.focus();
    }
  }

  async login() {
    await this._loginService.doLogin(this._router.url);
  }
}
