<uoa-header size="small" color="primary" logo-type="vertical" logo-position="middle">
  <div slot="end" class="color-primary flex-reverse">
    <div>
      <ion-menu-button color="primary" [attr.aria-label]="(userInfo$ | async) ? 'My Account' : 'Log in'">
        <div>
          <ion-icon name="person" aria-hidden="true"></ion-icon>
          <div class="font-s padding-bottom-2xs" (click)="login()">{{ (userInfo$ | async) ? 'My Account' : 'Log in' }}</div>
        </div>
      </ion-menu-button>
    </div>
  </div>
</uoa-header>
