import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AddSubjectModalComponent } from './add-subject-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LoadingIndicatorModule } from '../../loading-indicator';
const COMPONENT = [AddSubjectModalComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [IonicModule, CommonModule, LoadingIndicatorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddSubjectModalModule {}
