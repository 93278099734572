import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, take } from 'rxjs';
import { NceaService } from 'src/app/core/services/ncea.service';
import { NceaSubjectDetail } from 'src/app/domain/ncea-subject-detail.model';

@Component({
  selector: 'app-add-subject-modal',
  templateUrl: './add-subject-modal.component.html',
  styleUrls: ['./add-subject-modal.component.scss'],
})
export class AddSubjectModalComponent {
  startYear: number = 2002;
  currentYear: number = new Date().getFullYear();
  years: string[] = [];
  subjects: NceaSubjectDetail[];
  subjectNames: string[] = [];
  chosenYear: string;
  chosenSubject: string;
  chosenSubjectCode: string;
  loadingSubjects: boolean = false;
  valid = true;
  disableInput: boolean = true;
  hideSubjectField: boolean = false;
  designSubjects = [
    'Design (Practical Art)',
    'Sculpture (Practical Art)',
    'Design and Visual Communication',
    'Painting (Practical Art)',
    'Photography (Practical Art)',
    'Printmaking (Practical Art)',
  ];
  showDesignSubjectWarn: boolean = false;

  constructor(public modalController: ModalController, private _nceaService: NceaService) {
    for (let i = 0; i < this.currentYear - this.startYear + 1; i++) {
      let newYear = this.startYear + i;
      this.years.push(newYear.toString());
    }
    this.years = this.years.reverse();
  }

  dismiss(selectedItems = {}): void {
    this.modalController.dismiss(selectedItems);
  }

  subjectChosen(e: string): void {
    if (this.designSubjects.includes(e.trim())) {
      this.showDesignSubjectWarn = true;
    } else {
      this.showDesignSubjectWarn = false;
    }
    this.chosenSubject = e;
    const subject = this.subjects.find((subject) => subject.subjectName == this.chosenSubject);
    this.chosenSubjectCode = subject.subjectCode;
  }

  yearChosen(e: string): void {
    this.showDesignSubjectWarn = false;
    this.chosenYear = e;
    this.hideSubjectField = false;
    this.loadingSubjects = true;
    this.compileSubjects(this.chosenYear);
  }

  addSubject(): void {
    if (this.chosenYear && this.chosenSubject) {
      this.valid = true;
      this.dismiss({ year: this.chosenYear, subject: this.chosenSubject, subjectCode: this.chosenSubjectCode });
      this._nceaService.rankScoreDisplayUpdate();
    } else {
      this.valid = false;
    }
  }

  compileSubjects(year: string): void {
    combineLatest([this._nceaService.nceaSubjects$, this._nceaService.getNceaSubjects(year)])
      .pipe(take(1))
      .subscribe(
        ([subjects, data]) => {
          const subjectsList = subjects
            .filter((subs) => subs.year === year)
            .map((s) => s.subjectCode)
            .join(',');
          this.subjects = data.message as NceaSubjectDetail[];
          this.chosenSubject = '';
          this.subjectNames = this.subjects.filter((s) => !subjectsList.includes(s.subjectCode)).map((subject) => subject.subjectName);
          this.disableInput = false;
          this.loadingSubjects = false;
        },
        (error) => {
          this.loadingSubjects = false;
          this.hideSubjectField = true;
        }
      );
  }
}
