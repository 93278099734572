import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AddSubjectComponent } from './add-subject.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

const COMPONENT = [AddSubjectComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [IonicModule, CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddSubjectModule {}
