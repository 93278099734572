<ion-menu contentId="app" side="end" type="overlay" (keydown.esc)="handleEscapeKey($event)" (ionWillOpen)="menuWillOpen()">
  <ion-content>
    <ion-toolbar>
      <ng-template [ngIf]="loggedIn$ | async">
        <h2 #title class="font-large color-primary padding-left-l" tabindex="0">Welcome, {{ (user$ | async)?.firstName }}</h2>
        <div class="separator-line"></div>
      </ng-template>
      <ion-buttons slot="primary">
        <ion-menu-button color="primary" icon-only>
          <ion-icon name="close"></ion-icon>
        </ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
    <ion-list>
      <ng-template [ngIf]="!(loggedIn$ | async)" [ngIfElse]="logedIn">
        <ion-menu-toggle color="primary" fill="clear">
          <a (click)="login()" tabindex="0"> Log in/ Registration </a>
        </ion-menu-toggle>
      </ng-template>
      <ng-template #logedIn>
        <div class="margin-top-l">
          <ion-item lines="none">
            <ion-label>NCEA Permission</ion-label>
            <ion-toggle [checked]="hasNceaPermission$ | async" (click)="toggleNceaPermission()" color="success"></ion-toggle>
          </ion-item>
        </div>
      </ng-template>
    </ion-list>
  </ion-content>
</ion-menu>
