import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { LoginSuccessGuard } from '@uoa/auth';

const routes: Routes = [
  {
    path: '',
    canActivate: [LoginSuccessGuard],
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
  },

  {
    path: 'home',
    redirectTo: '',
    canActivate: [LoginSuccessGuard],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'ncea',
    loadChildren: () => import('./pages/ncea/ncea.module').then((m) => m.NCEAPageModule),
  },
  {
    path: 'cie',
    loadChildren: () => import('./pages/cie/cie.module').then((m) => m.CiePageModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
