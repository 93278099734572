<div tabindex="0" role="dialog">
  <ion-toolbar color="primary">
    <ion-title>
      <h1 class="font-default margin-0" id="title">Add a subject</h1>
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" id="close-button" icon-only>
        <ion-icon name="close" id="close-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="modal-content">
    <div class="select-year padding-bottom-s">
      <div class="padding-bottom-s" id="select-year">Select year taken</div>
      <combo-search-dropbox
        class="font-s"
        placeholder="Select year"
        [searchList]="years"
        (chosenItem)="yearChosen($event.detail)"
        id="year-dropbox"
      ></combo-search-dropbox>
      <div class="color-error padding-top-s error" *ngIf="!valid && !chosenYear" id="year-error">
        <ion-icon class="padding-right-xs font-l" name="alert-circle-sharp"></ion-icon><span>Please select a year</span>
      </div>
    </div>
    <div class="select-subject" *ngIf="!hideSubjectField">
      <div class="text-and-loader">
        <div class="padding-bottom-s">
          <div class="subject-text">Select Level 3 subject*</div>
          <app-loading-indicator *ngIf="loadingSubjects"></app-loading-indicator>
        </div>
      </div>

      <div>
        <combo-search-dropbox
          class="font-s"
          placeholder="Select subject"
          [searchList]="subjectNames"
          (chosenItem)="subjectChosen($event.detail)"
          [disable]="disableInput"
          [searchValue]="chosenSubject"
          id="subject-dropbox"
        ></combo-search-dropbox>
      </div>

      <div class="color-error padding-top-s error" *ngIf="!valid && !chosenSubject" id="subject-error">
        <ion-icon class="padding-right-xs font-l" name="alert-circle-sharp"></ion-icon>Please select a subject
      </div>
    </div>
    <div *ngIf="showDesignSubjectWarn" class="italic padding-top-s">Note: Design subjects may take longer to calculate.</div>
    <div *ngIf="hideSubjectField" class="d-flex error-message padding-top-s">
      <span class="bold padding-bottom-s">Oops! No subjects found :( </span>
      <span>We're still updating the subjects for this year.</span>
      <span>Try selecting a different year or come back later.</span>
    </div>

    <div class="display-flex flex-reverse">
      <ion-button
        shape="round"
        size="large"
        (click)="addSubject()"
        id="add-subject-button"
        class="width-150px"
        [disabled]="hideSubjectField"
      >
        Add subject
      </ion-button>
      <ion-button fill="outline" size="large" shape="round" (click)="dismiss()" id="cancel-button" class="width-150px"> Cancel </ion-button>
    </div>
  </div>
</div>
