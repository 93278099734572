<div tabindex="0" role="dialog">
  <ion-toolbar color="light">
    <ion-title>
      <h1 class="font-default margin-0" id="title">Disclaimer</h1>
    </ion-title>
    <ion-buttons slot="primary">
      <ion-button (click)="dismiss()" id="close-button" icon-only>
        <ion-icon name="close" id="close-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="modal-content">
    <div class="message">
      <span
        >This calculator provides you with an estimate of your Rank Score and hence only an indication of the University of Auckland
        programmes you may be eligible for.</span
      >
      <span
        >Your official Rank Score is calculated by the University directly from information provided by the relevant examining body (e.g.
        NZQA) and may differ from the estimate depending on the information provided.</span
      >
      <span>Only your official Rank Score is used when considering an Application for Admission to the University of Auckland.</span>
      <span
        >In order to be considered for admission to the programme(s) of your choice, you must apply through the formal application
        process.</span
      >
      <span>Each Application for Admission is subject to the University's admission and selection criteria.</span>
    </div>

    <div class="display-flex bottom-close-button">
      <ion-button shape="round" class="button-outline" (click)="dismiss()" fill="clear" size="small" class="cancel" id="close-button"
        >Close</ion-button
      >
    </div>
  </div>
</div>
