import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import { NceaStandardComponent } from './ncea-standard.component';

const COMPONENT = [NceaStandardComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [IonicModule, CommonModule, TooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NceaStandardModule {}
