import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { LoginService } from '@uoa/auth';
import { combineLatest, take, Observable } from 'rxjs';
import { AppStorageService } from 'src/app/core/services';
import { NceaService } from 'src/app/core/services/ncea.service';

import { NceaSubject } from 'src/app/domain';

@Component({
  selector: 'app-ncea-subject-card',
  templateUrl: './ncea-subject-card.component.html',
  styleUrls: ['./ncea-subject-card.component.scss'],
})
export class NceaSubjectCardComponent implements OnInit {
  @Input() subject: NceaSubject;
  scoreCalculated$: Observable<boolean>;
  initialValue = '';
  fromNzqa: boolean;
  constructor(
    private _nceaService: NceaService,
    private _alertCtrl: AlertController,
    private _aapStorage: AppStorageService,
    private _loginService: LoginService
  ) {}

  ngOnInit(): void {
    this.scoreCalculated$ = this._nceaService.calculatePushed$;
  }

  async validateAddStandard(suggestion) {
    let subjectIndex = -1,
      standardIndex = -1;
    if (suggestion) {
      this._nceaService.nceaSubjects$.pipe(take(1)).subscribe(async (subjects) => {
        suggestion.subjects.forEach((subject) => {
          subjectIndex = subjects.findIndex((subject) => {
            const cond = subject.year === suggestion.year && this.subject.subjectCode !== subject.subjectCode;
            if (cond) {
              standardIndex = subject.standards.findIndex((standard) => standard.standardCode == suggestion.standardCode);
            }
            return cond && standardIndex != -1;
          });
        });

        if (subjectIndex != -1 && standardIndex != -1) {
          const alert = await this._alertCtrl.create({
            header: 'Duplicate Standard',
            message:
              'This standard is already in your list. Adding under this subject will remove it from the previous subject standards list.',
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'proceed',
                role: 'confirm',
                handler: () => {
                  suggestion = subjects[subjectIndex].standards[standardIndex];
                  subjects[subjectIndex].standards.splice(standardIndex, 1);
                  subjects[subjectIndex].standardSuggestions.push(suggestion);
                  subjects[subjectIndex] = Object.assign({}, subjects[subjectIndex]);
                  this.addStandard(suggestion);
                  this._nceaService.updateSubjectsObservable(subjects);
                },
              },
            ],
          });
          alert.present();
        } else {
          this.addStandard(suggestion);
        }
      });
    }
  }

  addStandard(suggestion): void {
    this.subject.standardSuggestions = this.subject.standardSuggestions.filter((sug) => sug.standardCode !== suggestion.standardCode);
    this._nceaService.uncategorisedStandards$.pipe(take(1)).subscribe((uncategorised) => {
      if (uncategorised && uncategorised[suggestion.year]) {
        let standardIndex = uncategorised[suggestion.year].standards.findIndex(
          (standard) => standard.standardCode === suggestion.standardCode
        );
        if (standardIndex !== -1) {
          suggestion = uncategorised[suggestion.year].standards[standardIndex];
          uncategorised[suggestion.year].standards.splice(standardIndex, 1);
        }
      }
    });
    this.subject.standards.push(suggestion);
    setTimeout(() => {
      this.initialValue = this.initialValue + ' ';
    }, 300);
    this._nceaService.rankScoreDisplayUpdate();
  }

  delete() {
    let refinedSubjects;
    combineLatest([this._nceaService.nceaSubjects$, this._nceaService.uncategorisedStandards$])
      .pipe(take(1))
      .subscribe(([subjects, uncategorised]) => {
        refinedSubjects = subjects.filter(
          (subject) => !(subject.testComponent === this.subject.testComponent && subject.year === this.subject.year)
        );
        this._nceaService.updateSubjectsObservable(refinedSubjects);
        this._aapStorage.setItem('nceaResults', refinedSubjects);
        this._loginService.loggedIn$.pipe(take(1)).subscribe((loggedIn) => {
          if (loggedIn) {
            this._nceaService.saveSubjectStandards(refinedSubjects, uncategorised).subscribe();
          }
        });
      });
  }

  async confirmDelete() {
    const alert = await this._alertCtrl.create({
      header: 'Remove subject?',
      message: `Are you sure you want to remove the subject<strong class="color-dark"> Level 3 ${this.subject.year}  ${this.subject.subjectName} </strong>from this calculation?`,
      buttons: [
        { text: 'Cancel', role: 'cancel' },
        {
          text: 'Remove',
          role: 'confirm',
          handler: () => {
            this.delete();
          },
        },
      ],
    });

    await alert.present();
  }
}
