import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { IonicModule } from '@ionic/angular';
import { DisclaimerModalComponent } from './disclaimer-modal/disclaimer-modal.component';
import { UnlockFeatureModalModule } from '../unlock-feature-modal/unlock-feature-modal.module';
import { LoadingIndicatorModule } from '../loading-indicator';
import { ScoreBoxModule } from '../score-box/score-box.module';

const COMPONENT = [FooterComponent, DisclaimerModalComponent];

@NgModule({
  declarations: [COMPONENT],
  exports: [COMPONENT],
  imports: [CommonModule, IonicModule, LoadingIndicatorModule, ScoreBoxModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterModule {}
