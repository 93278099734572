import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoginService } from '@uoa/auth';
import { BypassErrorService } from '@uoa/error-pages';
import { BehaviorSubject, Observable, combineLatest, filter, map, shareReplay, skip, switchMap, take } from 'rxjs';
import { NceaStandards, NceaSubject, ResponseDto } from 'src/app/domain';
import { NceaCalcStandard } from 'src/app/domain/ncea-calc-standard.model';
import { environment } from 'src/environments/environment';
import { AppStorageService } from '.';
import { UserService } from './user.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NceaService {
  private _nceaSubjects = new BehaviorSubject<NceaSubject[]>([]);
  nceaSubjects$: Observable<NceaSubject[]>;

  private _nceaPermission = new BehaviorSubject<boolean>(true);
  hasNceaPermission$: Observable<boolean>;

  private _uncategorisedStandards = new BehaviorSubject<NceaStandards>(null);
  uncategorisedStandards$: Observable<NceaStandards>;

  private _calculatedTotalScore = new BehaviorSubject<string>('');
  calculatedTotalScore$: Observable<string>;

  private _calculatePushed = new BehaviorSubject<boolean>(false);
  calculatePushed$: Observable<boolean>;

  private _newlyAddedSubject = new BehaviorSubject<string>('');
  newlyAddedSubject$: Observable<string>;

  constructor(
    private _http: HttpClient,
    public storageService: AppStorageService,
    private _userService: UserService,
    private _loginService: LoginService,
    private _bypassErrorService: BypassErrorService,
    private _alertCtrl: AlertController
  ) {
    this.nceaSubjects$ = this._nceaSubjects.asObservable().pipe(
      map((subjects) => subjects.sort((a, b) => a.subjectName.localeCompare(b.subjectName))),
      map((subjects) => subjects.sort((a, b) => b.year.localeCompare(a.year))),
      shareReplay(1)
    );
    this.uncategorisedStandards$ = this._uncategorisedStandards.asObservable().pipe(shareReplay(1));
    this.storageService
      .getItem('nceaResults')
      .then((val) => {
        if (val) {
          this._nceaSubjects.next(val);
        }
      })
      .catch((error) => {});
    this.hasNceaPermission$ = this._nceaPermission.pipe(shareReplay(1));
    this.calculatedTotalScore$ = this._calculatedTotalScore.asObservable().pipe(shareReplay(1));
    this.calculatePushed$ = this._calculatePushed.asObservable().pipe(shareReplay(1));

    this.newlyAddedSubject$ = this._newlyAddedSubject.asObservable().pipe(shareReplay(1));
  }

  initNcea(): void {
    this._userService.loggedIn$
      .pipe(
        filter((loggedIn) => loggedIn === true),
        take(1)
      )
      .subscribe(async (_) => {
        await this.getNceaPermission();
        this._loginService.getUserInfo();
        this.getSavedSubjectStandards()
          .pipe(take(1))
          .subscribe((result: ResponseDto) => {
            const data = result.message.subjectstandards;
            if (data?.categorised && data?.categorised.length > 0) {
              let subjects = this._nceaSubjects.getValue();
              this.mergeSubjectAndStandards(data.categorised, subjects);
              this._nceaSubjects.next(subjects);
            }
            if (data?.uncategorised) {
              this._uncategorisedStandards.next(data.uncategorised);
            } else {
              this._uncategorisedStandards.next({});
            }
          });
      });
    const nceaRetrieved = sessionStorage.getItem('nceaRetrieved');
    combineLatest([this._userService.loggedIn$, this.hasNceaPermission$])
      .pipe(
        skip(1),
        untilDestroyed(this),
        filter(([loggedIn, permission]) => loggedIn && permission === true && 'true' !== nceaRetrieved),
        take(1),
        switchMap((_) => this.getNZQANceaStandards())
      )
      .subscribe(async (x) => {
        if (x['errorCode']) {
          await this.noNzqaAlert();
        } else {
          let uncategorised = x['message']['uncategorised'];
          let categorised = x['message']['categorised'];
          this._nceaSubjects.pipe(take(1)).subscribe((subjects) => {
            this.mergeSubjectAndStandards(categorised, subjects, true);
            subjects.forEach((sub) => {
              sub.standards.forEach((standard) => {
                if (uncategorised[sub.year]) {
                  uncategorised[sub.year].standards = uncategorised[sub.year].standards.filter(
                    (std) => std.standardCode !== standard.standardCode
                  );
                }
              });
            });
            this._nceaSubjects.next(subjects);
            setTimeout(() => this.saveSubjectStandards(subjects, uncategorised).pipe(take(1)).subscribe(), 1000);
          });
          this._uncategorisedStandards.next(uncategorised);
        }
        sessionStorage.setItem('nceaRetrieved', 'true');
      });
  }

  mergeSubjectAndStandards(categorisedSubjects, subjects, fromNzqa = false): void {
    categorisedSubjects.forEach((categorisedSubject) => {
      const subIndex = this.getSubjectIndex(subjects, categorisedSubject);
      if (subIndex != -1) {
        const subject = subjects[subIndex];
        categorisedSubject.standards.forEach((standard) => {
          if (fromNzqa) {
            standard.fromNzqa = true;
          }
          const index = this.getStandardIndex(subject?.standards, standard);
          if (index != -1) {
            subject.standards[index] = Object.assign(subject.standards[index], standard);
          } else {
            subject.standards.push(standard);
            const suggestionIndex = this.getStandardIndex(subject.standardSuggestions, standard);
            let x = subject.standardSuggestions.splice(suggestionIndex, 1);
            subject.standardSuggestions = subject.standardSuggestions.splice(suggestionIndex, 1);
          }
        });
      } else {
        if (fromNzqa) {
          categorisedSubject.fromNzqa = true;
          categorisedSubject.standardSuggestions = [];
          categorisedSubject.standards.forEach((standard) => {
            standard.fromNzqa = true;
          });
          this.getSuggestedStandards(categorisedSubject);
        }
        subjects.push(categorisedSubject);
      }
    });
  }

  async noNzqaAlert(): Promise<void> {
    const alert = await this._alertCtrl.create({
      header: 'NCEA Error',
      message: `Sorry, we can't find any NCEA result for you at the moment. This could be because your result haven't 
      been released yet or you've not studied level 3 NCEA. <br/><br/>Try checking back once your results are released, or 
      if there's an error in your data.`,
      buttons: [{ text: 'Close', role: 'cancel' }],
    });
    await alert.present();
  }

  getSubjectIndex(subjectsList, subject) {
    return subjectsList.findIndex((sub) => sub.subjectCode === subject.subjectCode && sub.year === subject.year);
  }

  getStandardIndex(standards, standard) {
    return standards.findIndex((std) => std.standardCode === standard.standardCode);
  }

  updateSubjectsObservable(subjects): void {
    this._nceaSubjects.next(subjects);
  }

  async getNceaPermission(): Promise<void> {
    let resp = await this._http.get<ResponseDto>(`${environment.apiUrl}ncea-permission`).toPromise();
    if (resp?.message) {
      this._nceaPermission.next(resp.message.permission);
    }
  }

  saveNceaPermission(permission: boolean): void {
    this._http
      .put<ResponseDto>(`${environment.apiUrl}ncea-permission`, { permission })
      .subscribe((res: ResponseDto) => this._nceaPermission.next(res.message.permission));
  }

  getSuggestedStandards(subject): void {
    this._getSubjectYearStandards(subject.year, subject.subjectCode)
      .pipe(take(1))
      .subscribe((data: ResponseDto) => {
        const sub = data.message;
        const str = subject.standards.map((s) => s.standardCode).join(',');
        subject.standardSuggestions = sub.standardSuggestions.filter((std) => !str.includes(std.standardCode));
      });
  }

  getSubjectYearStandards(year: string = '2022', subjectCode: string = '48000', name: string = 'History'): void {
    this._getSubjectYearStandards(year, subjectCode)
      .pipe(take(1))
      .subscribe((data: ResponseDto) => {
        const subject = data.message;
        subject.standards = [];
        this._nceaSubjects.pipe(take(1)).subscribe((data) => {
          this._nceaSubjects.next([...data, subject]);
        });
        this._newlyAddedSubject.next(subject.subjectCode + subject.year);
      });
  }

  getNceaSubjects(year: string): Observable<ResponseDto> {
    const endpoint = `${environment.apiUrl}subjects/ncea/search-by-year?year=${year}`;
    this._bypassErrorService.bypassError(endpoint, [404, 0]);
    return this._http.get<ResponseDto>(endpoint);
  }

  private _getSubjectYearStandards(year: string, subjectCode: string): Observable<ResponseDto> {
    return this._http.get<ResponseDto>(`${environment.apiUrl}standards/search-by-subject-year?year=${year}&subjectCode=${subjectCode}`);
    // return this._http.get<ResponseDto>('assets/data.json');
  }

  getNZQANceaStandards() {
    // return this._http.get('assets/searchByNceaResponse.json');
    const endpoint = `${environment.apiUrl}standards/search-by-ncea`;
    this._bypassErrorService.bypassError(endpoint, [500]);
    return this._http.get<ResponseDto>(endpoint);
  }

  saveSubjectStandards(standards: NceaSubject[], uncategorisedStandards: NceaStandards): Observable<ResponseDto> {
    return this._http.put<ResponseDto>(`${environment.apiUrl}standards/save`, {
      subjectstandards: { categorised: standards, uncategorised: uncategorisedStandards },
    });
  }

  getSavedSubjectStandards(): Observable<ResponseDto> {
    return this._http.get<ResponseDto>(`${environment.apiUrl}standards/save`);
  }

  getNceaCalculation(requestStandards: NceaCalcStandard[]): Observable<ResponseDto> {
    return this._http.post<ResponseDto>(`${environment.apiUrl}calculate/ncea`, requestStandards);
  }

  rankScoreDisplayUpdate(): void {
    this._calculatePushed.next(false);
    this._calculatedTotalScore.next('');
  }

  getRequestStandards() {
    let requestStandards = [];
    this.nceaSubjects$.pipe(take(1)).subscribe((subjects) => {
      subjects.forEach((subject) => {
        let testComponent = subject.testComponent;
        subject.standards.forEach((std) => {
          let result = null;
          if (false !== std.ueApproved) {
            if (std?.result) {
              result = std.result;
            } else if (std?.results?.length == 1) {
              result = std.results[0].code;
            }
            if (result) {
              let reqStd = new NceaCalcStandard(std.year, testComponent, std.standardCode, result, std.version, std.results[0].credit);
              requestStandards.push(reqStd);
            }
          }
        });
      });
    });

    this.uncategorisedStandards$.pipe(take(1)).subscribe((uncategorisedStdObject) => {
      let uncategorisedStandardsValues = uncategorisedStdObject ? Object.values(uncategorisedStdObject) : [];
      uncategorisedStandardsValues.forEach((uncategorisedStds) => {
        uncategorisedStds.standards.forEach((standard) => {
          let result = null;
          if (standard.ueApproved) {
            if (standard?.result) {
              result = standard.result;
            } else if (standard.results.length == 1) {
              result = standard.results[0].code;
            }
            if (result) {
              let reqStd = new NceaCalcStandard(
                standard.year,
                standard.subjects[0].testComponent,
                standard.standardCode,
                result,
                standard.version,
                standard.results[0].credit
              );
              requestStandards.push(reqStd);
            }
          }
        });
      });
    });
    return requestStandards;
  }

  calculate() {
    let requestStandards = this.getRequestStandards();
    if (requestStandards.length > 0) {
      this.getNceaCalculation(requestStandards)
        .pipe(take(1))
        .subscribe((response) => {
          this._calculatedTotalScore.next(response.message['totalScore']);
          let returnedStandards = response.message['nceaRankScore'];
          this._calculatePushed.next(true);

          combineLatest([this.nceaSubjects$, this.uncategorisedStandards$])
            .pipe(take(1))
            .subscribe(([nceaSubjects, uncategorised]) => {
              returnedStandards.forEach((returnedStd) => {
                let stdFound = false;
                nceaSubjects.forEach((subjects) => {
                  let standard = subjects.standards.find(
                    (std) =>
                      std.standardCode == returnedStd.standardCode &&
                      ((std.result && std.result == returnedStd.result) ||
                        (std.results.length === 1 && std.results[0].code == returnedStd.result))
                  );
                  if (stdFound === true && standard) {
                    standard.rankScore = undefined;
                    standard.include = false;
                  } else if (standard) {
                    stdFound = true;
                    standard.rankScore = returnedStd.rankScore;
                    standard.include = returnedStd.include;
                  }
                });
                if (!stdFound) {
                  const years = Object.values(uncategorised);
                  let uncatStdFound = false;
                  years.forEach((year) => {
                    let standard = year.standards.find((std) => {
                      return (
                        std.standardCode == returnedStd.standardCode &&
                        ((std.result && std.result == returnedStd.result) || std.results[0].code == returnedStd.result)
                      );
                    });
                    if (uncatStdFound === true && standard) {
                      standard.rankScore = undefined;
                      standard.include = false;
                    } else if (standard) {
                      uncatStdFound = true;
                      standard.rankScore = returnedStd.rankScore;
                      standard.include = returnedStd.include;
                    }
                  });
                }
              });
            });
        });
    } else {
      this._calculatedTotalScore.next(null);
    }
  }
}
