import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';

import { AuthModule, CognitoConfigService, StorageService } from '@uoa/auth';
import { ErrorPagesModule, ErrorToastService, UoaErrorsConfig } from '@uoa/error-pages';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppAuthConfigService, AppStorageService } from './core/services';
import { MenuComponent } from './shared/components/menu/menu.component';
import { HttpClientModule } from '@angular/common/http';
import { FooterModule, HeaderModule, LoadingIndicatorModule } from './shared';
import { AppErrorsConfig } from './core/services/app-errors-config.service';
import { AppToastService } from './core/services/app-toast.service';

@NgModule({
  declarations: [AppComponent, MenuComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    ErrorPagesModule,
    IonicStorageModule.forRoot(),
    IonicModule.forRoot({ animated: false }),
    HttpClientModule,
    HeaderModule,
    FooterModule,
    LoadingIndicatorModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: CognitoConfigService, useClass: AppAuthConfigService },
    { provide: StorageService, useClass: AppStorageService },
    { provide: HttpClientModule },
    { provide: UoaErrorsConfig, useClass: AppErrorsConfig },
    { provide: ErrorToastService, useClass: AppToastService },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
