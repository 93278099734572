import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NceaService } from 'src/app/core/services/ncea.service';
import { AddSubjectModalComponent } from './add-subject-modal';

@Component({
  selector: 'app-add-subject',
  templateUrl: './add-subject.component.html',
  styleUrls: ['./add-subject.component.scss'],
})
export class AddSubjectComponent {
  constructor(public modalController: ModalController, private _nceaService: NceaService) {}

  async openModal() {
    const modal = await this.modalController.create({
      component: AddSubjectModalComponent,
      cssClass: 'add-subject-modal',
    });
    modal.present();
    modal.onDidDismiss().then((result) => {
      let data = result.data;
      if (data?.year && data?.subjectCode) {
        this._nceaService.getSubjectYearStandards(data.year, data.subjectCode, data.subject);
      }
    });
  }
}
