<div class="padding-left-l padding-right-l padding-top-s">
  <div>
    <div class="calculate-button">
      <ion-button
        aria-label="calculate"
        class="btn-min-width"
        shape="round"
        (click)="calculate()"
        [disabled]="(loading | async) || (disableCalculation | async)"
        id="calculate-button"
        ><span *ngIf="!(loading | async)">Calculate</span>
        <app-loading-indicator *ngIf="loading | async" id="loading-calculate"></app-loading-indicator>
      </ion-button>
    </div>
    <div class="save-button">
      <ion-button
        aria-label="save"
        shape="round"
        fill="outline"
        class="btn-min-width"
        (click)="onSaveResults()"
        [disabled]="saving | async"
        id="save-button"
      >
        <strong><span *ngIf="!(saving | async)">Save</span></strong>
        <app-loading-indicator *ngIf="saving | async" id="loading-save"></app-loading-indicator>
      </ion-button>
    </div>
    <div class="color-dark-1 score-box">
      <div class="total-score">
        <div class="italic padding-right-s">Estimated Rank Score</div>
        <div>
          <span *ngIf="calculatedRankScore != ''" class="score color-primary">
            {{ calculatedRankScore }}
          </span>
          / {{ totalRankScore }}
        </div>
      </div>
    </div>
  </div>
</div>
