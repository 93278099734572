import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  imageUrl: string = 'https://cdn.amazon.auckland.ac.nz/images/suggestions/suggestions-768w.jpg';
  constructor(private _platform: Platform) {}

  ngOnInit() {
    const platFormWidth = this._platform.width();
    if (platFormWidth > 1200) {
      this.imageUrl = this.imageUrl.replace('-768w', '-1600w');
    } else if (platFormWidth > 768 && platFormWidth <= 1200) {
      this.imageUrl = this.imageUrl.replace('-768w', '-1280w');
    }
  }
}
