<ion-app>
  <ion-content>
    <div class="content-wrapper">
      <app-header></app-header>
      <div class="banner-image">
        <div class="overlay"></div>
        <img aria-hidden="true" class="cover-image" [src]="imageUrl" />
      </div>

      <div class="inner-content">
        <div class="margin-top-l padding-bottom-xl">
          <h1 class="title color-light">Estimated rank score calculator</h1>
          <router-outlet id="app"></router-outlet>
        </div>
      </div>

      <app-footer></app-footer>
    </div>
  </ion-content>

  <app-menu></app-menu>
</ion-app>
