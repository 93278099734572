import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { LoginService } from '@uoa/auth';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-unlock-feature-modal',
  templateUrl: './unlock-feature-modal.component.html',
  styleUrls: ['./unlock-feature-modal.component.scss'],
})
export class UnlockFeatureModalComponent implements AfterViewInit {
  @ViewChild('content', { read: ElementRef }) content: ElementRef<HTMLElement>;

  constructor(
    public viewCtrl: ModalController,
    private _loginService: LoginService,
    private _router: Router,
    private _userService: UserService
  ) {}

  close() {
    this.viewCtrl.dismiss().then((alert) => {});
  }

  goToLogin() {
    this._userService.hideLeavePopup = true;
    this._loginService.doLogin(this._router.url);
    this.viewCtrl.dismiss();
  }

  ngAfterViewInit() {
    setTimeout(() => this.content.nativeElement.focus(), 400);
  }
}
