export class NceaCalcStandard {
  year: string;
  testComponent: string;
  standardCode: string;
  result: string;
  version: number;
  credit: number;

  constructor(year: string, testComponent: string, standardCode: string, result: string, version: number, credit: number) {
    this.year = year;
    this.testComponent = testComponent;
    this.standardCode = standardCode;
    this.result = result;
    this.version = version;
    this.credit = credit;
  }
}
