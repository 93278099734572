<ion-row [ngClass]="{ greyed: false == standard.ueApproved, bottomBorder: index + 1 < standardsLength }">
  <ion-col size-xs="12" size-sm="12" size-md="1" class="number">
    <strong>{{ standard?.standardCode }}</strong>
    <ion-button (click)="delete()" *ngIf="standard.fromNzqa == null" fill="none" size="small" aria-label="delete standard"
      ><ion-icon name="close-circle" aria-hidden="true"></ion-icon
    ></ion-button>
  </ion-col>
  <ion-col size-xs="12" size-sm="12" size-md="5" class="title">
    <div>{{ standard?.title }}</div>
    <ng-container *ngIf="standard?.results?.length" id="credits">
      <div>({{ standard?.results[0]?.credit }} credits)</div>
    </ng-container></ion-col
  >
  <ion-col size-xs="12" size-sm="7" size-md="3" class="grades">
    <div *ngIf="standard?.results?.length > 1" class="dropdown">
      <ion-select
        [value]="standard.result"
        (ionChange)="gradeSelected($event.detail.value)"
        placeholder="Select results"
        interface="popover"
        size="cover"
        disabled="false"
      >
        <ion-select-option [value]="null">Select Results</ion-select-option>
        <ion-select-option *ngFor="let grade of standard?.results" [value]="grade.code"> {{ grade.description }} </ion-select-option>
      </ion-select>
    </div>
    <span *ngIf="false != standard.ueApproved && 1 == standard?.results?.length" class="color-dark-1 grade">{{
      standard?.results[0]?.description
    }}</span>
    <span *ngIf="false === standard.ueApproved" class="not-approved">Not University Entrance approved</span>
  </ion-col>
  <ion-col size-xs="12" size-sm="3" size-md="3" class="rank-score-col">
    <div class="rankscore" *ngIf="false != standard.ueApproved">
      <div class="rankscore" *ngIf="standard.include == true && scoreCalculated && standard.rankScore">
        <strong>{{ standard.rankScore }}</strong>
        <span class="italic rank-score-text">Rank score</span>
      </div>
      <span
        *ngIf="standard.include == false && scoreCalculated"
        class="fake-link"
        trigger="hover"
        autoPlacement="true"
        placement="top"
        theme="light"
        id="not-used"
        [tooltip]="notUsedText"
        >*Not used</span
      >
      <div *ngIf="!scoreCalculated">
        <strong class="rankscore-placeholder-space"><br /></strong>
        <span class="italic rank-score-text">Rank score</span>
      </div>
      <div *ngIf="scoreCalculated && !standard.rankScore" class="rankscore-space"></div>
    </div>
    <ion-button *ngIf="!standard.fromNzqa" (click)="delete()" fill="none" size="small" id="delete-standard" aria-label="delete standard"
      ><ion-icon name="close-circle" aria-hidden="true"></ion-icon
    ></ion-button>
  </ion-col>
</ion-row>
